const model = {
  new_password: '',
  confirm_password: '',
};

const form = [
  {
    type: 'password',
    name: 'new_password',
    label: 'Nouveau mot de passe',
    placeholder: 'Votre nouveau mot de passe',
    component: 'el-input',
    required: true,
    is_password: true,
  },
  {
    type: 'password',
    name: 'confirm_password',
    label: 'Confirmer le nouveau mot de passe',
    placeholder: 'Confirmez votre nouveau mot de passe',
    component: 'el-input',
    required: true,
    is_password: true,
  },
];

const rules = {
  new_password: [
    {
      type: 'required',
      message: 'Veuillez entrer votre nouveau mot de passe',
    },
    {
      type: 'minLength',
      minLength: 8,
      message: 'Le mot de passe doit contenir au minimum 8 caractères'
    },
    {
      type: 'password',
      message: "Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un symbole.",
    },
  ],
  confirm_password: [
    {
      type: 'required',
      message: 'Veuillez entrer la confirmation de votre mot de passe',
    },
    {
      type: 'confirm',
      confirmField: 'new_password',
      message: 'Les mots de passe ne correspondent pas',
    },
  ],
};

export { model, form, rules };
