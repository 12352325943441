<template>
  <app-container
      v-if="!isTextLoading"
      :icon="texts.icon"
      colWidth="col-12 col-lg-6"
  >
    <template #header>
      <h1 class="text-dark mb-4">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="tokens">
        <div class="form-col mt-4">
          <app-form
              :button="texts.button"
              :form="form"
              :model="model"
              :rules="rules"
              @submitted="handleSubmit"
          />
        </div>
      </div>
    </template>
  </app-container>
</template>

<script>
// check texts from Utilities

import {form, model, rules} from '@/config/forms/auth/reset_password';
import Database from '@/utils/services/Database';
import Utilities from '@/utils/services/Utilities';
import useTexts from '@/mixins/useTexts';

export default {
  name: 'ResetPassword',
  mixins: [useTexts],
  data() {
    return {
      form,
      model,
      rules,
      page: 'reset_password',
    };
  },
  async created() {
    const tokenParams = this.$route.params.token;
    const {data, status} = await Database.getToken(tokenParams)

    if (status === 200) {
      if (!data.token) {
        this.$router.push({name: 'Home'});
        return Utilities.showMessage('error', 'token_not_existed');
      }
    } else {
      return Utilities.showMessage('error', 'reset_password_error');
    }
  },
  methods: {
    async handleSubmit(data) {
      const token = this.$route.params.token;
      const {status} = await Database.create('actions', {
        token: token,
        ...data,
        type: 'UPDATE_PASSWORD',
      });

      if (status !== 201)
        return Utilities.showMessage('error', 'reset_password_error');

      Utilities.showMessage('success', 'reset_password_success');
      this.$router.push({name: 'Login'});
    },
  },
};
</script>

<style lang="scss">
.password-row {
  margin-top: 3rem;

  h1 {
    font-size: 1.8rem;
    font-weight: 500;
  }

  .card-static {
    padding: 2rem 1rem;
  }
}
</style>
